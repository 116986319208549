import "bootstrap-select"
import "ajax-bootstrap-select"

// 検索ボックスに入れられる文字列の最大長
const MAX_SEARCH_LENGTH = 500;

// ajax-bootstrap-selectのデフォルトオプションをこちらに書く
// 全ページで共通のオプションをこちらで表現するため
// $.fn.ajaxSelectPicker.defaultsを参照しているのでそれを更新(/node_modules/ajax-bootstrap-select/dist/js/ajax-bootstrap-select.js)の44行目参照
// https://github.com/truckingsim/Ajax-Bootstrap-Select#options を参照
$.extend(true, $.fn.ajaxSelectPicker.defaults, $.fn.ajaxSelectPicker.defaults, {
  ajax: {
    type: "POST",
    dataType: "json",
    data: function () {
      return {
        q: '{{{q}}}'
      }
    }
  },
  cache: false,       // キャッシュをONにすると，1.空文字列の検索結果が初期状態となっていまう，2.文字列以外の条件(facility_idでフィルタする等)で検索結果がおかしくなる，という2つの不具合が発生する
  emptyRequest: true, // 空文字列でも検索できる
  bindEvent: "keyup", // defaultはkeyupのみ．
  // 日本語化
  locale: {
    currentlySelected: "選択済",
    emptyTitle: "未選択",
    errorText: "検索に失敗しました",
    // searchPlaceholder: "キーワードで検索", // 長いとboxに表示しきれない
    searchPlaceholder: "100件まで表示：検索語で絞込可", // 長いとboxに表示しきれない
    statusInitialized: "",
    statusNoResults: "検索結果: なし",
    statusSearching: "検索中",
    statusTooShort: "文字数が少なすぎます"
  },
  preserveSelected: true,  // falseにすると検索ボックスに文字を入力すると現在の選択したものが解除されてしまう問題があるため，trueにしている
  preserveSelectedPosition: 'before', // "検索中"のような状態メッセージは選択中のoptionの下に表示される．そのため，検索結果のoptionも選択中のoptionの下にあった方が統一感があっていいと思った．
  requestDelay: 1000, // 平さんがdatatableの遅延時間を1000ミリ秒としているのを参考にした
  templates: {
    status: "<div class='abs-status-msg'></div>" // div.statusだと，他のcssとかぶる可能性が高いので作成するDOMのクラスを変更
  }
});

// options.bsOptions(default: {}): selectpicerに渡すオプションハッシュ (ただし，検索フィールドは必ず必要なため，liveSearch: trueを上書きすることはできない)
// options.absOptions(default: {}): ajaxSelectPickerに渡すオプションハッシュ
// options.preserveSearchValue(default: true): 検索文字列を保存するか
// options.clearButton(default: true): 選択解除ボタンをつけるか
var defaultOpts = {
  bsOptions: {
    styleBase: 'bs-form-control' // buttonのclassをbtnではなくbs-form-controlにして枠をつける
  },
  absOpts: {},
  preserveSearchValue: true,
  clearButton: true
}

// 注意！
// このハンドラの第一引数の$selectはDOMがただ一つに特定できるときにしか正しく動かないので気をつけること
// $('...')で候補が複数出てくる場合は，each文でこのコンストラクタを呼ぶこと
export class AjaxBootstrapSelectHandler {
  constructor($select, options = {}) {
    options = $.extend(true, {}, defaultOpts, options) // このクラスのデフォルトオプションを引数のoptionで上書き

    // bootstrap-selectとajax-bootstrap-selectのオプションはここでしか使わないため，deleteしておく
    let bsOpts = options["bsOptions"];
    let absOpts = options["absOptions"];
    delete options["bsOptions"];
    delete options["absOptions"];

    // 元となるセレクトボックスDOM (イベントリスナーはこのDOMにつける)
    this.$select = $select.selectpicker(
      $.extend(true, bsOpts, { liveSearch: true }) // 検索フィールドを表示するため必ず，liveSearch: trueをオプションに加える
    ).ajaxSelectPicker($.extend(true, {}, absOpts));

    if (this.$select.val() === "") {
      // 選択済みデータを空にすることで、ブランクが選択済みに出てしまうことを防ぐ
      // selectタグの中身が空になっていることが条件
      this.$select.data('AjaxBootstrapSelect').list.selected = [];
    }

    // 最初に入っている値を×ボタンでクリアしてから，検索までの間はformに該当セレクトボックスの値が送信されなくなる
    //   name="hoge"というセレクトボックスの場合，hoge: "" という空文字が送信されるべきだが，
    //   hoge自体が送信されなくなってしまう
    //   これにより，hogeカラムに更新が入らなくなる
    // titleという属性を追加して，data-abs-locale-empty-titleと同じ値を入れることで解決できた(コンソール上からDOMの動きを見ただけなので，根拠は分からない)
    this.$select.attr('title', this.$select.attr('data-abs-locale-empty-title'));

    this.$select.selectpicker('refresh');

    this.$abs = $select.closest('div.bootstrap-select'); // bootstrap-selectの一番親にあたるDOM．closestで一番近いもののみを対象にしている
    this.$button = this.$abs.find('button.dropdown-toggle') // 見かけ上のセレクトタグ(実際はボタンタグになっている)
    this.$dropdown = this.$abs.find('div.dropdown-menu') // ドロップダウン
    this.$searchbox = this.$abs.find('input'); // 検索用inputフィールド
    this.searchVal = ""; // 検索文字列を保存するためのインスタンス変数．ドロップダウンが開かれたときに，前回の入力内容が削除されてしまうため，この変数で保存して入力し直す

    this.options = options;

    if (this.options["preserveSearchValue"]) {
      //this.setupPreserveSearchValue();
    }

    if (this.options["clearButton"]) {
      this.setupClearButton();
    }

    // 最初から一覧にデータが入っているように、初期化が終わった頃に強制keyup
    // ajax-bootstrap-select 自身が500msのsetTimeoutで初期化しているので，
    // こっちも500msまってkeyupを発行する
    let that = this;
    this.$select.one('loaded.bs.select', function() {
      setTimeout(function() {
        that.$searchbox.trigger('keyup');
      }, 500);
    });

    // 検索ボックスの長さの最大値を設定する
    this.$searchbox.attr("maxlength", MAX_SEARCH_LENGTH);
    // 検索ボックスのデザインを変える
    this.$searchbox.removeClass("form-control");
    this.$searchbox.addClass("bs-form-control");

    // 閉じた時に，全件検索をするように
    this.setupRequestOnClose();

    // DOMにこのインスタンスを設定する
    this.$select.data("abs-handler", this);
  }

  // ドロップダウンが開かれたら，検索するようにする
  // keyupイベントを発火させて検索させる
  setupRequestOnOpen() {
    let that = this;
    this.$select.on('show.bs.select', function() {
      that.$searchbox.trigger('keyup');
    });
  }

  // ドロップダウンを閉じたら，検索するようにする
  // keyupイベントを発火させて検索させる
  setupRequestOnClose() {
    let that = this;
    this.$select.on('hide.bs.select', function() {
      that.$searchbox.val(''); // 次に開くまでvalueが残ってしまうのでinputを空にする
      that.$searchbox.trigger('keyup');
    });
  }

  // 検索文字列を保存するためにbindするイベント
  // bootstrap-selectで実現するオプションがないため，自力で実装した
  setupPreserveSearchValue() {
    let that = this;
    // ドロップダウンが閉じるときに検索文字列をインスタンス変数で保存
    // 次回ドロップダウンを開いたときにその変数をinputフィールドに代入
    this.$select.on('hide.bs.select', function() {
      that.searchVal = that.$searchbox.val();
    })

    // ドロップダウンが開かれる瞬間に保存したvalueをinputフィールドに代入
    // shownにすると，ユーザにinputフィールドが表示されてから入力が始まってしまうのでshowの瞬間にしている
    this.$select.on('show.bs.select', function() {
      that.$searchbox.val(that.searchVal);
    });

    // ドロップダウンが開ききったら，カーソルを末尾にする
    // フォーカスの当たる瞬間と，valueが更新される瞬間が前後するとカーソルが先頭になってしまうため
    this.$select.on('shown.bs.select', function() {
      let cursorPos = that.$searchbox.val().length;
      that.$searchbox.get(0).setSelectionRange(cursorPos, cursorPos);
    });
  }

  // 選択解除ボタンを右側につけて，解除できるようにする
  // 解除ロジックがめんどくさそうなので，ライブラリに押し込めた
  setupClearButton() {
    let $clearButtonDom = $('<a class="clear-abs-button" href=""><i class="material-icons text-secondary" style="font-size: 1.2rem;">highlight_off</i></a>');
    this.$abs.addClass("d-flex align-items-center");
    $clearButtonDom.css({ 'margin-left' : '-60px', 'z-index' : 1000 });
    this.$abs.append($clearButtonDom);

    this.$clearButton = this.$abs.find('a.clear-abs-button');

    // ×ボタンをクリックしたら未選択の状態にする
    let that = this;
    this.$clearButton.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      that.clear();
    });
  }

  // 未選択の状態にする
  clear() {
    this.$select.selectpicker('val', '');
    this.$select.selectpicker('refresh');

    // 選択済みデータを空にすることで、ブランクが選択済みに出てしまうことを防ぐ
    this.$select.data('AjaxBootstrapSelect').list.selected = [];

    // keyupを呼ぶことでajax-bootstrap-selectに新しいリストで再描画させる
    this.$searchbox.val(''); // 閉じる前の文字列が残っているのでクリアする．クリアしないと前回の再検索をするだけとなってしまう
    this.$searchbox.trigger('keyup');
  }

  propReadonly(bool) {
    // postした時にはデータを送信したいのでセレクトボックスはreadonlyにする
    this.$select.prop('readonly', bool);
    // みかけ上のセレクトボックスはボタンタグなのでdisabledにしてクリックイベントを阻止しつつ、背景色がreadonlyっぽいものにする
    this.$button.prop('disabled', bool);
    // クリアボタンも無効にする
    this.$clearButton.addClass('d-none', bool);
  }
}
